html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-attachment: fixed;
  background-size: cover;
  background-position: top;
  background-image: url('./assets/img/fonsGala12.png');
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

footer {
  margin-top: auto; /* Pushes footer to the bottom */
  width: 100%;
}

.background-image {
  background-attachment: fixed;
  background-size: cover;
  background-position: top;
}
